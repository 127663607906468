<template>
  <div class="data-deletion">
    <div class="data-deletion__body">
      <h1>Data Deletion Instructions</h1>
      <p>
        All your data is safe and will not be used for any advertising purposes
        without your knowledge. If you want to delete your data (account data,
        completed tests and other personal data) located on the psyhotest.com
        website, please contact hello@psyhotest.com. Enter "Data Deletion
        Request" in the email title.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.data-deletion {
  & p {
    font-size: 16px;
  }

  & * {
    text-align: left;
  }

  &__body {
    width: 100%;
    max-width: 1000px;
    margin: 0px auto;

    padding: 25px 25px;

    @media screen and (max-width: 700px) {
      padding: 25px 15px;
    }
  }
}
</style>